<template>
    <v-card flat class="d-flex align-center" height="100%">
        <v-card max-width="800" class="mx-auto" outlined>
            <v-card-text class="pa-4">
                <v-row v-if="unsubscribed === 'no'">
                    <v-col cols="12" class="text-center">
                        <div class="pt-4 text-h4">
                            {{ $t("unsubscribe_from_mailing_list")}}
                        </div>
                    </v-col>
                    <v-col v-if="$route.params.mailing === 'wagon_tracking'" cols="12" class="text-center">
                        <div class="text-subtitle-1">
                            {{ $t("are_you_sure_you_want_to_unsubscribe_from_the_wagon_deployment_mailing_list") }}
                        </div>
                    </v-col>
                    <v-col v-else cols="12" class="text-center">
                        <div class="text-subtitle-1">
                            {{ $t("this_mailing_list_doesnt_exist") }}
                        </div>
                    </v-col>
                </v-row>
                <v-row v-if="unsubscribed === 'yes'">
                    <v-col cols="12" class="text-center">
                        <div>
                            <v-icon color="green" size="150">mdi-check-circle</v-icon>
                        </div>
                    </v-col>
                    <v-col cols="12" class="pb-4 text-center">
                        <div class="text-h4">
                            {{ $t("you_have_successfully_unsubscribed_from_the_mailing_list") }}
                        </div>
                    </v-col>
                </v-row>
                <v-row v-if="unsubscribed === 'err'">
                    <v-col cols="12" class="text-center">
                        <div>
                            <v-icon color="red" size="150">mdi-alert-circle</v-icon>
                        </div>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <div class="text-h4">
                            {{ $t("invalid_mailing_list_or_subscriber") }}
                        </div>
                        <div class="pt-6 text-subtitle-1">
                            {{ $t("please_contact_support") }}
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions v-if="unsubscribed !== 'yes'" class="pa-4 justify-center">
                <v-btn type="button" :disabled="$route.params.mailing !== 'wagon_tracking'" :loading="loading" :block="$vuetify.breakpoint.xsOnly" color="primary" @click="unsubscribe">{{ $t("unsubscribe") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex"

export default {
    name: "Mailing",
    data() {
        return {
            loading: false,
            unsubscribed: 'no',
        };
    },
    computed: {
        ...mapGetters(["lang"]),
    },
    methods: {
        ...mapActions(['setLanguage']),
        setLang() {
            let language = this.$route.query?.lang ?? this.getCookie('language')

            if (language) {
                document.documentElement.lang = language
                this.$vuetify.lang.current = document.documentElement.lang
                this.$i18n.locale = document.documentElement.lang
                this.setLanguage(document.documentElement.lang)
            }
            if (typeof this.$i18n.locale !== 'undefined') {
                this.$i18n.locale = document.documentElement.lang
            }
            this.$moment.locale(this.$i18n.locale)
            if (typeof this.$vuetify.lang.current !== 'undefined') {
                this.$vuetify.lang.current = document.documentElement.lang
            }
        },
        async unsubscribe() {
            if (!this.$route.params.mailing || !this.$route.params.uuid) return
            if (this.$route.params.mailing !== 'wagon_tracking') return
            this.loading = true
            var formData = new FormData()
            formData.append("uuid", this.$route.params.uuid)
            formData.append("mailing", this.$route.params.mailing)
            if (this.lang) {
                formData.append("language", this.lang)
            }
            await this.$http
                .put(`client/mailing/${this.$route.params.mailing}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                })
                .then((res) => {
                    //this.$toastr.success(this.$t("you_have_successfully_unsubscribed_from_the_mailing_list"))
                    this.unsubscribed = 'yes'
                })
                .catch((err) => {
                    //this.$toastr.error(this.$t("client_has_not_been_updated"))
                    this.unsubscribed = 'err'
                })
                .finally((end) => {
                    this.loading = false
                })
        },
    },
    mounted() {
        console.log('mounted lang', this.lang)
        console.log('mounted this.$route.query.lang', this.$route.query?.lang)
        this.setLang()
    },
};
</script>
